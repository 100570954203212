// import { Controller } from "@hotwired/stimulus"
import { Controller } from "stimulus"
import intlTelInput from 'intl-tel-input';
import utils from 'intl-tel-input/build/js/utils.js?1715428968906';
// import utils from 'intl-tel-input/js/utils.js?1715428968906';

// Connects to data-controller="phone"
export default class extends Controller {
  static targets = ["phone", "output"]

  connect() {
    const input = document.querySelector("#phone");
    // const output = this.outputTarget;
    const output = document.querySelector("#output");
    this.phoneTarget.addEventListener('change', this.handleChange);
    this.phoneTarget.addEventListener('keyup', this.handleChange);

    this.phoneTarget.type = "tel"
    this.iti = intlTelInput(this.phoneTarget, {
      customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
        return "777-111-2222";
      },
      utilsScript: utils,
      initialCountry: "kz",
      // separateDialCode: false,
      nationalMode: true,
      // placeholderNumberType:"MOBILE",
      // autoPlaceholder:"polite",
      placeholderNumberType: "aggressive",
      strictMode: true,
      hiddenInput: () => ({ phone: "full_phone", country: "country_code" }),
    })
  }

  handleChange = () => {
    const input = this.phoneTarget;
    // console.log(input.value)
    // const input = this.element.querySelector("#phone");
    const output = this.element.querySelector("#output");
    let text;

    if (input.value) {
      text = this.iti.isValidNumber()
        // ? "Valid number! Full international format: " + this.iti.getNumber()
        ? "Valid number!"
        : "Invalid number - please try again";
    } else {
      text = "Please enter a valid number below";
    }

    output.textContent = text;
  };
}
