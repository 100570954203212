// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

import consumer from './channels/consumer'
import CableReady from "cable_ready"
import mrujs from "mrujs";
import { CableCar } from "mrujs/plugins"

import "./custom/companion"

import PhotoSwipeLightbox from 'photoswipe/lightbox';

mrujs.start({
  plugins: [
    new CableCar(CableReady, { mimeType: "application/vnd.cable-ready.json" })
    // new CableCar(CableReady)
  ]
})

CableReady.initialize({ consumer })

const lightbox = new PhotoSwipeLightbox({
  gallery: '#gallery--pools',
  children: 'a',
  pswpModule: () => import('photoswipe')
});
lightbox.init();
