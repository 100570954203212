// import { Controller } from "@hotwired/stimulus"
import { Controller } from "stimulus"
import intlTelInput from 'intl-tel-input';
import utils from 'intl-tel-input/build/js/utils.js';

export default class extends Controller {
  static targets = ["tel", "email", "telInput", "emailInput"]

  connect() {
    this.emailTarget.hidden = true
    this.emailInputTarget.value = ""
    this.emailInputTarget.id = "email"
    this.emailInputTarget.name = "email"
    intlTelInput(this.telInputTarget, {
      customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
        return "777-111-2222" + selectedCountryPlaceholder;
      },
      utilsScript: utils,
      initialCountry: "kz",
      placeholderNumberType: "aggressive",
      strictMode: true
    })
  }

  // TODO: надо исправить
  toggle() {
    if (this.emailTarget.hidden == true) {
      this.telInputTarget.value = ""
      this.telInputTarget.id = "tel"
      this.telInputTarget.name = "tel"
      this.telTarget.hidden = true
      this.emailTarget.hidden = false
      this.emailInputTarget.name = "user[login]"
    } else {
      this.emailInputTarget.value = ""
      this.emailInputTarget.id = "email"
      this.emailInputTarget.name = "email"
      this.emailTarget.hidden = true
      this.telTarget.hidden = false
      this.telTarget.hidden.name = "user[login]"
    }
  }
}
