// import { Controller } from "@hotwired/stimulus"
import { Controller } from "stimulus"

// Connects to data-controller="registration"
export default class extends Controller {
  static targets = ["name", "email", "password", "error"];

  submit(event) {
    event.preventDefault();
    console.log(this.nameTarget)

    if (this.validateForm()) {
      this.element.submit();
    }
  }

  validateForm() {
    let valid = true;

    // Проверяем валидность имени пользователя
    if (!this.nameTarget.value) {
      this.showError(this.nameTarget, "Name is required");
      valid = false;
    } else {
      this.clearError(this.nameTarget);
    }

    // Проверяем валидность электронной почты
    if (!this.emailTarget.value.includes("@")) {
      this.showError(this.emailTarget, "Email is invalid");
      valid = false;
    } else {
      this.clearError(this.emailTarget);
    }

    // Проверяем валидность пароля
    if (this.passwordTarget.value.length < 6) {
      this.showError(this.passwordTarget, "Password must be at least 6 characters long");
      valid = false;
    } else {
      this.clearError(this.passwordTarget);
    }

    return valid;
  }

  showError(target, message) {
    target.classList.add("error");
    const errorTarget = this.errorTargets.find(item => item.dataset.errorFor === target.dataset.registrationTarget);
    errorTarget.textContent = message;
  }

  clearError(target) {
    target.classList.remove("error");
    const errorTarget = this.errorTargets.find(item => item.dataset.errorFor === target.dataset.registrationTarget);
    errorTarget.textContent = "";
  }
}
